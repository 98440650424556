import * as React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";

const IndexPage = ({ data }) => {
  const blogList = data.allMarkdownRemark.edges;

  return (
    <div className="container">
      <Metadata title="Blogs" description="Blogs home" />

      <Header />

      <article>
        <h3 className="main">Blogs</h3>

        {blogList.map(({ node }) => (
          <a
            href={node.frontmatter.link}
            className="article-link"
            target="_blank"
            key={node.frontmatter.slug}
          >
            <div className="time">
              <div className="month">
                {node.frontmatter.date.split(",")[0].slice(0, 3)}
              </div>
              <div className="year">{node.frontmatter.date.split(",")[1]}</div>
            </div>

            <div className="details">
              <div className="title">{node.frontmatter.title}</div>

              <div className="summary">{node.frontmatter.summary}</div>
            </div>
          </a>
        ))}
      </article>

      <Footer />
    </div>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            summary
            link
            slug
            date(formatString: "MMMM, YYYY")
          }
        }
      }
    }
  }
`;
